var scroll = new SmoothScroll('a[href*="#"]', {
    speed: 500,
    speedAsDuration: true,
    updateURL: false,
    popstate: false,
    topOnEmptyHash: true
});

var scrollUp = document.getElementById("up");
if (scrollUp) {
    scrollUp.addEventListener("click", function () {
        scroll.animateScroll(0);
    });
}

window.addEventListener("load", fullLoaded);

function fullLoaded() {
    let body = document.getElementById('body');
    let prehome = document.getElementById('js-prehome');
    let prehomeToggle = document.getElementById('js-prehomeToggle');
    body.classList.add('loaded');
    setTimeout(() => {
        body.classList.add('animation-start');
    }, 2000, body);
    if (prehomeToggle && !prehomeToggle.classList.contains('show')) {
        setTimeout(() => {
            prehome.classList.remove('hide');
            setTimeout(() => {
                prehome.classList.add('animate');
            }, 2000, prehome);
        }, 6000, prehome);
    }
    initCarousel();
}

// UP ARROW
function scrollUpToggle() {
    if (window.pageYOffset > 100) {
        if (this.oldScroll > this.scrollY) {
            document.getElementById("up").classList.add('active');
        } else {
            document.getElementById("up").classList.remove('active');
        }
    } else {
        document.getElementById("up").classList.remove('active');
    }
    this.oldScroll = this.scrollY;
}

//BURGER MENU
const header = document.getElementById('header-wrapper');

window.onload = function () {
    scrollUpToggle();
};

window.onscroll = function () {
    scrollUpToggle();
};

/* CUSTOM JS */

//Carousel
var carouselIsPaused;

function initCarousel() {
    if (document.querySelector('.carousel-wrapper')) {
        var carouselItems = document.querySelectorAll('.carousel-item-holder');
        var carouselButtons = document.querySelectorAll('.carousel-item-content-buttons');

        carouselIsPaused = false;
        var carouselTimer = window.setInterval(function () {
            if (document.querySelector('.carousel-wrapper').style.display !== 'none') {
                if (!carouselIsPaused) {
                    loadNextCarousel();
                }
            } else {
                carouselIsPaused = true;
            }
        }, 8000);

        function loadNextCarousel() {
            Array.prototype.slice.call(carouselItems).forEach(element => {
                if (carouselItems.length > 1) {
                    if (element.classList.contains('active')) {
                        element.classList.remove('active');
                        setTimeout(function () {
                            element.classList.remove('enter');
                            if (!element.nextElementSibling) {
                                carouselLoadItem(carouselItems[0]);
                            } else {
                                carouselLoadItem(element.nextElementSibling);
                            }
                        }, 500);
                    }
                }
            });
        }

        function carouselLoadItem(element) {
            enterCarouselItem(element);
            showCarouselItem(element);
        }

        function enterCarouselItem(element) {
            element.classList.add('enter')
        }

        function showCarouselItem(element) {
            setTimeout(function () {
                element.classList.add('active')
            }, 100);
        }

        Array.prototype.slice.call(carouselButtons).forEach(element => {
            element.addEventListener('mouseenter', function (e) {
                carouselIsPaused = true;
            });
            element.addEventListener('mouseleave', function (e) {
                carouselIsPaused = false;
            });
        });

        carouselLoadItem(carouselItems[0]);
    }
}

//Change page after Click on data-target element
var currentTarget;
var documentTitle = document.title;
Array.prototype.slice.call(document.querySelectorAll('[data-target]')).forEach(el => {
    el.addEventListener('click', (e) => {
        e.preventDefault();
        currentTarget = el.getAttribute('data-target');

        var URL = window.location.href;
        var arr = URL.split('/');
        var newPage = currentTarget !== 'uvod' ? currentTarget : '';

        var pageTitle = returnPageTitle(currentTarget);
        document.title = pageTitle + ' | ' + documentTitle;
        history.pushState(null, null, e.target.href);
        window.history.replaceState(null, null, "/" + newPage);

        document.getElementById('body').classList.remove('loaded');
        setTimeout(() => {
            hideDataPart(document.querySelectorAll(`[data-part]`));
            showDataPart(document.querySelectorAll(`[data-part="${currentTarget}"]`));
            document.getElementById('body').classList.remove('animation-start');
            scroll.animateScroll(document.getElementById('body'));
            if (currentTarget === 'uvod') {
                showDataPart(document.querySelectorAll(`[data-part="ciele"]`));
            }
            if (el.getAttribute('data-calctype')) {
                window.imgTimer.stop();
                showCalcContent(el.getAttribute('data-calctype'));
                if (el.getAttribute('data-calctype') === 'ine') {
                    document.querySelector('.calculator-content-type-wrapper').style.display = 'none';
                } else {
                    document.querySelector('.calculator-content-type-wrapper').style.display = 'block';
                }
                initChart(el.getAttribute('data-calctype'));
            }
            setTimeout(() => {
                document.getElementById('body').classList.add('loaded');
                setTimeout(() => {
                    document.getElementById('body').classList.add('animation-start');
                }, 3000)
            }, 1000)
        }, 1000);
    });
});

function hideDataPart(arr) {
    Array.prototype.slice.call(arr).forEach(el => {
        el.style.display = 'none';
    });
}

function showDataPart(arr) {
    Array.prototype.slice.call(arr).forEach(el => {
        el.style.display = 'block';
    });
}

//Window URL
function returnPageTitle(el) {
    if (el === 'ciele') {
        return 'Ciele';
    } else if (el === 'vypocet-investovania') {
        return 'Výpočet investovania';
    } else if (el === 'uvod') {
        return 'Úvod';
    } else if (el === 'cookies') {
        return 'Cookies';
    } else if (el === 'dakujeme') {
    return 'Ďakujeme';
    }
    else {
        return el;
    }
}

function openSubpageFromURL() {
    var URL = window.location.href;
    var arr = URL.split('/');

    if (arr[3].length > 0) {
        var tempTitle = arr[3];
        if (arr[3].includes('vypocet')) {
            arr[3] = 'vypocet-investovania'
        }
        if (arr[3].includes('ciele') && arr[4]) {
            var itemNumber;
            if (arr[4] === 'hypoteka') {
                itemNumber = 1;
                tempTitle = 'Ciele - Hypotéka';
            } else if (arr[4] === 'dochodok') {
                itemNumber = 2;
                tempTitle = 'Ciele - Dôchodok';
            } else if (arr[4] === 'dalsia-generacia') {
                itemNumber = 3;
                tempTitle = 'Ciele - Ďalšia generácia';
            } else if (arr[4] === 'jednorazova-investicia') {
                itemNumber = 4;
                tempTitle = 'Ciele - Jednorázová investícia';
            }
            document.querySelector('.goals-item').classList.add('goal-expand', 'goal-expand-' + itemNumber);
            hideOtherGoalItems(document.querySelector('.goals-item-inner[data-goal="' + itemNumber + '"]'), true);
        }
        if(arr[3].includes('ciele') && !arr[4]) {
            hideExpandedGoals();
        }
        var pageTitle = returnPageTitle(tempTitle);
        document.title = pageTitle + ' | ' + documentTitle;
        hideDataPart(document.querySelectorAll(`[data-part]`));
        showDataPart(document.querySelectorAll(`[data-part="${arr[3]}"]`));
    } else {
        hideExpandedGoals();
        hideDataPart(document.querySelectorAll(`[data-part]`));
        showDataPart(document.querySelectorAll(`[data-part="uvod"]`));
        showDataPart(document.querySelectorAll(`[data-part="ciele"]`));
    }
}

function hideExpandedGoals() {
    Array.prototype.slice.call(document.querySelectorAll('.goals-detail')).forEach(el => {
        if(el.classList.contains('goal-expanded')) {
            el.classList.remove('goal-expanded');
        }
    });
    document.querySelector('.goals-wrapper').classList.remove('goal-expanded');
    document.querySelector('.goals-wrapper').style.display = 'block';
    document.querySelector('.goals-wrapper').style.zIndex = '1';
    document.querySelector('.goals-content').classList.remove('goal-expanded');
    document.querySelector('.goals-title').classList.remove('goal-expanded');
    Array.prototype.slice.call(document.querySelectorAll('.goals-detail')).forEach(el => {
        el.style.display = 'none';
    });
    Array.prototype.slice.call(document.querySelectorAll('.goals-item-inner')).forEach(el => {
        el.parentElement.classList.remove('goal-expanded', 'goal-expand', 'goal-expand-1', 'goal-expand-2', 'goal-expand-3', 'goal-expand-4');
    });
    if(window.imgTimer) {
        window.imgTimer.stop();
    }
}

window.addEventListener("popstate", function () {
    openSubpageFromURL();
}, false);

openSubpageFromURL();


//Goals
Array.prototype.slice.call(document.querySelectorAll('.goals-item-inner')).forEach(el => {
    el.addEventListener('click', (e) => {
        var currentGoal = el.getAttribute('data-goal');
        var currentGoalText;
        var tempTitle;

        if (currentGoal === '1') {
            currentGoalText = 'hypoteka';
            tempTitle = 'Ciele - Hypotéka';
        } else if (currentGoal === '2') {
            currentGoalText = 'dochodok';
            tempTitle = 'Ciele - Dôchodok';
        } else if (currentGoal === '3') {
            currentGoalText = 'dalsia-generacia';
            tempTitle = 'Ciele - Ďalšia generácia';
        } else if (currentGoal === '4') {
            currentGoalText = 'jednorazova-investicia';
            tempTitle = 'Ciele - Jednorázová investícia';
        }

        var URL = window.location.href;
        var arr = URL.split('/');

        document.title = tempTitle + ' | ' + documentTitle;
        history.pushState(null, null, e.target.href);
        window.history.replaceState(null, null, "/ciele/" + currentGoalText);

        el.parentElement.classList.add('goal-expand', 'goal-expand-' + el.getAttribute('data-goal'));
        hideOtherGoalItems(el);
    }, el);
});

function hideOtherGoalItems(curr, scrollTop) {
    var currData = curr.getAttribute('data-goal');

    if (scrollTop) {
        scroll.animateScroll(0);
    } else {
        scroll.animateScroll(document.querySelector('.goals'));
    }

    document.querySelector('.goals-wrapper').classList.add('goal-expanded');
    document.querySelector('.goals-content').classList.add('goal-expanded');
    document.querySelector('.goals-title').classList.add('goal-expanded');
    Array.prototype.slice.call(document.querySelectorAll('.goals-item-inner:not([data-goal=\"' + currData + '\"])')).forEach(el => {
        el.parentElement.classList.add('goal-expanded');
    });
    hideDetailItem(document.querySelectorAll('.goals-detail'));
    setTimeout(() => {
        var currentDetail = document.querySelector('.goals-detail[data-goaldetail=\"' + currData + '\"]');
        setTimeout(() => {
            document.querySelector('.goals-wrapper').style.display = 'none';
            currentDetail.style.display = 'block';
            setTimeout(() => {
                currentDetail.classList.add('goal-expanded');
                document.querySelector('.goals-wrapper').style.zIndex = '-1';
                goalsDetailImagesInit(currentDetail);
            }, 100);
        }, 500);
    }, 500);
}


//Goals detail nav
var goalsNavItems = document.querySelectorAll('.goals-detail-item-nav-item');
var goalsDetailItems = document.querySelectorAll('.goals-detail');
Array.prototype.slice.call(goalsNavItems).forEach(el => {
    el.addEventListener('click', (e) => {
        var currentGoal = el.getAttribute('data-goal');
        var currentGoalText;
        var tempTitle;

        scroll.animateScroll(document.querySelector('.goals'));

        if (currentGoal === '1') {
            currentGoalText = 'hypoteka';
            tempTitle = 'Ciele - Hypotéka';
        } else if (currentGoal === '2') {
            currentGoalText = 'dochodok';
            tempTitle = 'Ciele - Dôchodok';
        } else if (currentGoal === '3') {
            currentGoalText = 'dalsia-generacia';
            tempTitle = 'Ciele - Ďalšia generácia';
        } else if (currentGoal === '4') {
            currentGoalText = 'jednorazova-investicia';
            tempTitle = 'Ciele - Jednorázová investícia';
        }

        var URL = window.location.href;
        var arr = URL.split('/');

        document.title = tempTitle + ' | ' + documentTitle;
        history.pushState(null, null, e.target.href);
        window.history.replaceState(null, null, "/ciele/" + currentGoalText);

        setTimeout(function () {
            if (!el.classList.contains('active')) {
                hideDetailItem(goalsDetailItems);
                showDetailItem(goalsDetailItems, el);
            }
        }, 500, el);
    });
});

function hideDetailItem(arr) {
    Array.prototype.slice.call(arr).forEach(el => {
        if (el.classList.contains('goal-expanded')) {
            el.classList.remove('goal-expanded');
            setTimeout(() => {
                el.style.display = 'none';
            }, 1000, el);
        }
    });
}

function showDetailItem(arr, curr) {
    Array.prototype.slice.call(arr).forEach(el => {
        if (el.getAttribute('data-goaldetail') === curr.getAttribute('data-goal')) {
            setTimeout(() => {
                el.style.display = 'block';
                setTimeout(() => {
                    el.classList.add('goal-expanded');
                    goalsDetailImagesInit(el);
                }, 100, el);
            }, 1000, el);
        }
    });
}

function Timer(fn, t) {
    var timerObj = setInterval(fn, t);

    this.stop = function () {
        if (timerObj) {
            clearInterval(timerObj);
            timerObj = null;
        }
        return this;
    };

    // start timer using current settings (if it's not already running)
    this.start = function () {
        if (!timerObj) {
            this.stop();
            timerObj = setInterval(fn, t);
        }
        return this;
    };

    // start with new or original interval, stop current interval
    this.reset = function (newT = t) {
        t = newT;
        return this.stop().start();
    };
}

//Goals detail change images,text,bullets
function goalsDetailImagesInit(el) {
    var currentDetail = el;
    var goalsDetailItems = currentDetail.querySelectorAll('.goals-detail-item-img-item');
    window.imgTimer = new Timer(function () {
        if (el.style.display !== 'none') {
            window.imgTimer.reset(3000);
            loadNextImage();
        }
    }, 3000);

    function loadNextImage(id) {
        Array.prototype.slice.call(goalsDetailItems).forEach(element => {
            if (goalsDetailItems.length > 1) {
                if (id) {
                    element.classList.remove('active');
                    if (element.getAttribute('data-item') === id) {
                        goalsLoadItem(element, goalsDetailItems);
                        selectDotGoalsItem(element.getAttribute('data-item'), currentDetail);
                        selectTextGoalsItem(element.getAttribute('data-item'), currentDetail);
                    }
                } else {
                    if (element.classList.contains('active')) {
                        element.classList.remove('active');
                        setTimeout(function () {
                            if (!element.nextElementSibling) {
                                goalsLoadItem(goalsDetailItems[0], goalsDetailItems);
                                selectDotGoalsItem(goalsDetailItems[0].getAttribute('data-item'), currentDetail);
                                selectTextGoalsItem(goalsDetailItems[0].getAttribute('data-item'), currentDetail);
                            } else {
                                goalsLoadItem(element.nextElementSibling, goalsDetailItems);
                                selectDotGoalsItem(element.nextElementSibling.getAttribute('data-item'), currentDetail);
                                selectTextGoalsItem(element.nextElementSibling.getAttribute('data-item'), currentDetail);
                            }
                        }, 500);
                    }
                }
            } else {
                window.imgTimer.stop();
            }
        });
    }

    function goalsLoadItem(curr, parent) {
        var all = Array.prototype.slice.call(parent);
        all.forEach(ele => {
            ele.classList.remove('active');
        });
        curr.classList.add('active');
    }

    function selectDotGoalsItem(curr, parent) {
        var all = Array.prototype.slice.call(parent.querySelectorAll('.goals-detail-item-img-nav span'));
        all.forEach(ele => {
            ele.classList.remove('active');
        });
        all[curr].classList.add('active');
    }

    function selectTextGoalsItem(curr, parent) {
        var all = Array.prototype.slice.call(parent.querySelectorAll('.goals-detail-item-title-text span'));
        all.forEach(ele => {
            if (ele.nextElementSibling || all.length > 1) {
                ele.classList.remove('active');
            }
        });
        if (all.length > 1) {
            all[curr].classList.add('active');
        }
    }

    Array.prototype.slice.call(currentDetail.querySelectorAll('.goals-detail-item-img-nav span')).forEach(element => {
        element.addEventListener('click', function () {
            window.imgTimer.reset(3000);
            loadNextImage(element.getAttribute('data-item'));
        });
    });


    selectDotGoalsItem(goalsDetailItems[0].getAttribute('data-item'), currentDetail);
    selectTextGoalsItem(goalsDetailItems[0].getAttribute('data-item'), currentDetail);
    goalsLoadItem(goalsDetailItems[0], goalsDetailItems);

}

//Calculator
var calcNavButtons = document.querySelectorAll('.js-calc-main-btn');
var calcNavContent = document.querySelectorAll('.js-calc-content');
var allCalcButtons = document.querySelectorAll('.js-calc-btn');
var allReverseButtons = document.querySelectorAll('.js-calc-reverse');

//Main navigation toggle elements in calculator
Array.prototype.slice.call(calcNavButtons).forEach(element => {
    element.addEventListener('click', function () {
        element.parentElement.querySelector('.selected').classList.remove('selected');
        element.classList.add('selected');
        showCalcContent(element.getAttribute('data-goal'));
        if (element.getAttribute('data-goal') === 'ine') {
            document.querySelector('.calculator-content-type-wrapper').style.display = 'none';
        } else {
            document.querySelector('.calculator-content-type-wrapper').style.display = 'block';
        }
        var currentValue = getValue();
        var currentYearsEle = getYears();
        var currentYears = calcYears(currentYearsEle.noUiSlider.get());
        var currentMortgage = getMortgage();
        var currentMortgageRate = getMortgageRate();
        customUpdateChart(
            document.querySelector('.calculator-content-goal .selected').getAttribute('data-goal'),
            document.querySelector('.calculator-content-type .selected').getAttribute('data-type'),
            currentYears,
            currentValue,
            currentMortgage,
            currentMortgageRate
        );
    });
});

function showCalcContent(elData) {
    var content = document.querySelector('.calculator-content-dynamic');
    if(content) {
        content.classList.remove('active');
        content.classList.add('deactive');
        Array.prototype.slice.call(calcNavContent).forEach(element => {
            if (element.getAttribute('data-goal') === elData) {
                element.style.display = "block";
            } else {
                element.style.display = "none";
            }
        });
        content.classList.remove('deactive');
        content.classList.add('active');
    }
}

//Calculator elements toggle selected
Array.prototype.slice.call(allCalcButtons).forEach(element => {
    element.addEventListener('click', function () {
        toggleSelected(element);
    });
});

//Calculator elements reverse
Array.prototype.slice.call(allReverseButtons).forEach(element => {
    element.addEventListener('change', function () {
        var valueWrappers = document.querySelectorAll('.calculator-content-value-wrapper');
        var valueParent;
        var currentInput;
        var newValue;
        var typeBtn = document.querySelector('.calculator-content-type .js-calc-btn.selected').getAttribute('data-type');

        if(typeBtn === 'kon') {
            type = 5;
        } else if(typeBtn === 'dyn') {
            type = 8;
        } else {
            type = 10;
        }

        if(element.getAttribute('data-goal') === 'dch-year') {
            var currentIndex = 0;
            if(typeBtn === 'kon') {
                currentIndex = 0;
            } else if(typeBtn === 'dyn') {
                currentIndex = 1;
            } else {
                currentIndex = 2;
            }
            var currentVal = myLine.data.datasets[currentIndex].data[myLine.data.datasets[currentIndex].data.length - 1] / (element.value * 12);
            document.getElementById('js-calc-price-dch').value = Math.round(currentVal);
        }
        else {
            var currentYearsEle = getYears();
            var currentYears = calcYears(currentYearsEle.noUiSlider.get());
            chcem_dosiahnut = currentYears;

            Array.prototype.slice.call(valueWrappers).forEach(element => {
                if (element.style.display === 'block') {
                    valueParent = element
                }
            });

            currentInput = valueParent.querySelector('.calculator-content-value input');

            if(element.getAttribute('data-goal') === 'dch') {
                finalna_suma = element.value * (20 * 12);
            } else if (element.getAttribute('data-goal') === 'hyp') {
                finalna_suma = myLine.data.datasets[3].data[element.value - 1];
                chcem_dosiahnut = element.value;
            }
            else {
                finalna_suma = element.value;
            }

            newValue = Math.round(interpolacia());

            currentInput.value = newValue;
            currentInput.setAttribute('data-value', newValue);
            toggleSelected(currentInput);
        }
    });
});


function toggleSelected(element) {
    if (element.parentElement.querySelector('.selected')) {
        element.parentElement.querySelector('.selected').classList.remove('selected');
    }
    element.classList.add('selected');
    var currentValue = getValue();
    var currentYearsEle = getYears();
    var currentYears = calcYears(currentYearsEle.noUiSlider.get());
    var currentMortgage = getMortgage();
    var currentMortgageRate = getMortgageRate();
    customUpdateChart(
        document.querySelector('.calculator-content-goal .selected').getAttribute('data-goal'),
        document.querySelector('.calculator-content-type .selected').getAttribute('data-type'),
        currentYears,
        currentValue,
        currentMortgage,
        currentMortgageRate
    );
}

//Range sliders
var hypSlider = document.getElementById('hyp-slider');
if(hypSlider) {
    noUiSlider.create(hypSlider, {
        start: 30,
        step: 1,
        connect: [true, false],
        format: wNumb({
            decimals: 0
        }),
        tooltips: wNumb({suffix: "rokov", decimals: 0}),
        range: {
            'min': 4,
            'max': 30
        }
    });
}

var hypSliderUver = document.getElementById('hyp-slider-uver');
if(hypSliderUver) {
    noUiSlider.create(hypSliderUver, {
        start: 100000,
        step: 1000,
        connect: [true, false],
        format: wNumb({
            decimals: 0,
        }),
        tooltips: wNumb({suffix: "€", thousand: ' ', decimals: 0}),
        range: {
            'min': 5000,
            'max': 500000
        }
    });
}

var hypSliderUrok = document.getElementById('hyp-slider-urok');
if(hypSliderUrok) {
    noUiSlider.create(hypSliderUrok, {
        start: 1.58,
        step: 0.01,
        connect: [true, false],
        format: wNumb({
            decimals: 2
        }),
        tooltips: wNumb({suffix: "%", decimals: 2}),
        range: {
            'min': 0.1,
            'max': 5
        }
    });
}

var dchSlider = document.getElementById('dch-slider');
if(dchSlider) {
    noUiSlider.create(dchSlider, {
        start: [35, 65],
        step: 1,
        connect: true,
        tooltips: [wNumb({suffix: "rokov", decimals: 0}), wNumb({suffix: "rokov", decimals: 0})],
        range: {
            'min': 18,
            'max': 80
        }
    });
}

var detSlider = document.getElementById('det-slider');
if(detSlider) {
    noUiSlider.create(detSlider, {
        start: 10,
        step: 1,
        connect: [true, false],
        format: wNumb({
            decimals: 0
        }),
        tooltips: wNumb({suffix: "rokov", decimals: 0}),
        range: {
            'min': 1,
            'max': 50
        }
    });
}

var ineSlider = document.getElementById('ine-slider');
if(ineSlider) {
    noUiSlider.create(ineSlider, {
        start: 8,
        step: 1,
        connect: [true, false],
        format: wNumb({
            decimals: 0
        }),
        tooltips: wNumb({suffix: "rokov", decimals: 0}),
        range: {
            'min': 3,
            'max': 30
        }
    });
}
Array.prototype.slice.call(document.querySelectorAll('.js-period-slider')).forEach(element => {
    element.noUiSlider.on('set.one', function () {
        setYears();
    });
});

//Calculator Chart

function customUpdateChart(goal, type, rok, vklad, hypoteka, urok) {

    if (goal !== 'ine') {
        if (type === "kon") {
            calculateData(goal, vklad, rok, 5, 0, 'kon');
            calculateData(goal, vklad, rok, 8, 1, 'kon');
        } else if (type === "dyn") {
            calculateData(goal, vklad, rok, 8, 1, 'dyn');
            calculateData(goal, vklad, rok, 10, 2, 'dyn');
        } else if (type === "riz") {
            calculateData(goal, vklad, rok, 8, 1, 'riz');
            calculateData(goal, vklad, rok, 10, 2, 'riz');
        } else {
            calculateData(goal, vklad, rok, 5, 0, 'kon');
            calculateData(goal, vklad, rok, 8, 1, 'kon');
        }
        myLine.data.labels = createLabels(rok);
    } else {
        calculateData(goal, vklad, rok, 10);
        myLine.data.labels = createLabels((rok + 5));
    }
    if (goal === 'hyp') {
        calculateData(goal, vklad, rok, 0, 0, 'hyp', hypoteka, urok);
    }

    myLine.update();
}

function createLabels(element) {
    var labelsArray = [];
    for (var i = 0; i <= element; i++) {
        labelsArray.push(i);
    }
    return labelsArray;
}

function setHypoYear() {
    var arr;
    var interestYear;
    var selectedType = document.querySelector('.calculator-content-type .selected').getAttribute('data-type');
    var hypoYear = parseInt(document.getElementById('hyp-slider').noUiSlider.get(), 10);
    var yearElement = document.querySelector('.js-hypo-years');

    if (selectedType === 'kon') {
        arr = myLine.data.datasets[0].data;
    } else if (selectedType === 'dyn') {
        arr = myLine.data.datasets[1].data;
    } else {
        arr = myLine.data.datasets[2].data;
    }

    interestYear = isPoint(arr, myLine.data.datasets[3].data);
    yearElement.value = interestYear ? interestYear : (hypoYear + 1);
}

function isPoint(arr, arr2) {
    var a = arr;
    var b = arr2;
    for (var i = 0; i < a.length; i++) {
        if (a[i] > b[i]) {
            return i;
        }
    }
}

//function to calculate the amortization data
var amortData = [];

function amort(balance, interestRate, terms) {
    amortData = [];

    //Calculate the per month interest rate
    var monthlyRate = interestRate / 12;

    //Calculate the payment
    var payment = balance * (monthlyRate / (1 - Math.pow(1 + monthlyRate, -terms)));

    for (var count = 0; count < terms; ++count) {
        var interest = balance * monthlyRate;
        var monthlyPrincipal = payment - interest;
        var amortInfo = {
            Balance: balance.toFixed(2),
            Interest: balance * monthlyRate,
            MonthlyPrincipal: monthlyPrincipal
        }
        amortData.push(amortInfo);
        balance = balance - monthlyPrincipal;
    }
}

var monthlyRepayment = 0;
var monthlyInterest = 0;

//calculate function
function calculateLoan(hypo, rok, urok) {
    var loanBorrow = parseFloat(hypo);
    var interestRate = parseFloat(urok) / 1200;
    var totalTerms = 12 * rok;

    //Monthly
    var schedulePayment = Math.round(loanBorrow * interestRate / (1 - (Math.pow(1 / (1 + interestRate), totalTerms))));
    monthlyRepayment = schedulePayment;
    var totalInterestPay = totalTerms * schedulePayment;
    amort(loanBorrow, parseFloat(urok) / 100, totalTerms);

    monthlyInterest = (totalInterestPay - loanBorrow) / totalTerms;
}

function getAmortData(dataType, terms) {
    var dataValue = 0;
    switch (dataType) {
        case "interest":
            for (var i = 0; i < terms; i++) {
                dataValue += parseFloat(amortData[i].Interest);
            }
            break;
        case "balance":
            dataValue = parseFloat(amortData[terms - 1].Balance);
            break;
    }
    return Math.round(dataValue);
}

function calculateData(goal, vklad, rok, urok, pole, type, hypoVal, hypoUrok) {
    var goalType = goal;
    var interval = goalType === 'ine' ? 1 : 12;
    var vkladNumber = Number(vklad);
    var dataArray = [vkladNumber];
    var dataArrayHyp = [parseFloat(hypoVal)];
    var dataArrayIne2 = [vkladNumber];
    var dataArrayIne3 = [vkladNumber];
    var finalValue,
        finalInvest,
        finalProfit;

    //Calculate Data
    if (goalType === 'dch' || goalType === 'det' || goalType === 'hyp') {
        //Dochodok vypocet
        for (var i = 1; i <= rok; i++) {
            var a = urok / interval / 100;
            var t = i * interval;
            var n = vklad * ((Math.pow(1 + a, t) - 1) / a);
            dataArray.push(formatNumberToNumber(n));
        }
        finalValue = n;
        finalInvest = n - (vklad * interval * rok);
        finalProfit = vklad * interval * rok;
    } else if (goalType === 'ine') {
        //Ine vypocet
        var n = vkladNumber;
        var n2, n3, tempRok = rok + 5;
        for (var i = 1; i <= tempRok; i++) {
            n = n + ((urok / 100) * n);
            if (i <= (tempRok - 5)) {
                n2 = formatNumberToNumber(n);
                dataArrayIne2.push(formatNumberToNumber(n));
            } else {
                dataArrayIne2.push(formatNumberToNumber(n2));
            }
            if (i <= (tempRok)) {
                n3 = formatNumberToNumber(n);
                dataArrayIne3.push(formatNumberToNumber(n));
            } else {
                dataArrayIne3.push(formatNumberToNumber(n3));
            }
        }
        finalValue = n2;
        finalInvest = n2 - vklad;
        finalProfit = vklad;
    }
    if (goalType === 'hyp' && type === 'hyp') {
        //Hypoteka vypocet
        calculateLoan(hypoVal, rok, hypoUrok);

        var dt = new Date();
        var yearCounter = 1;

        for (var i = dt.getFullYear(); i < dt.getFullYear() + rok; i++) {
            dataArrayHyp.push(
                getAmortData("balance", 12 * yearCounter)
            );
            yearCounter++;
        }
    }

    var inputYear;
    if(goalType === 'dch') {
        inputYear = 20;
    }

    //Render data to view
    if (goalType === 'dch' || goalType === 'det' || goalType === 'hyp') {
        if (type === 'kon') {
            myLine.data.datasets[2].data = [];
            if (pole === 0) {
                myLine.data.datasets[0].data = dataArray;
                document.getElementById('js-type-1').parentElement.className = 'kon';
                document.getElementById('js-type-1').innerText = 'Konzervatívny';
                document.getElementById('js-type-val-1').innerText = '5% p.a.';
                document.getElementById('js-hodnota-1').innerText = formatNumberToString(finalValue) + ' €';
                document.getElementById('js-zisk-1').innerText = formatNumberToString(finalInvest) + ' €';
                document.getElementById('js-invest-1').innerText = formatNumberToString(finalProfit) + ' €';
                if (goalType === 'dch') {
                    document.getElementById('js-additional-1').innerText = formatNumberToString(finalValue / (inputYear * 12)) + ' €';
                    var newFinalValue = finalValue / (inputYear * 12);
                    document.getElementById('js-calc-price-dch').value = newFinalValue.toFixed(0);
                }
                if (goalType === 'det') {
                    document.getElementById('js-calc-price-det').value = finalValue.toFixed(0);
                }
            }
            if (pole === 1) {
                myLine.data.datasets[1].data = dataArray;
                document.getElementById('js-type-2').parentElement.className = 'dyn';
                document.getElementById('js-type-2').innerText = 'Vyvážený';
                document.getElementById('js-type-val-2').innerText = '8% p.a.';
                document.getElementById('js-hodnota-2').innerText = formatNumberToString(finalValue) + ' €';
                document.getElementById('js-zisk-2').innerText = formatNumberToString(finalInvest) + ' €';
                document.getElementById('js-invest-2').innerText = formatNumberToString(finalProfit) + ' €';
                if (goalType === 'dch') {
                    document.getElementById('js-additional-2').innerText = formatNumberToString(finalValue / (inputYear * 12)) + ' €';
                }
            }
        } else if (type === 'dyn') {
            myLine.data.datasets[0].data = [];
            if (pole === 1) {
                myLine.data.datasets[1].data = dataArray;
                document.getElementById('js-type-1').parentElement.className = 'dyn';
                document.getElementById('js-type-1').innerText = 'Vyvážený';
                document.getElementById('js-type-val-1').innerText = '8% p.a.';
                document.getElementById('js-hodnota-1').innerText = formatNumberToString(finalValue) + ' €';
                document.getElementById('js-zisk-1').innerText = formatNumberToString(finalInvest) + ' €';
                document.getElementById('js-invest-1').innerText = formatNumberToString(finalProfit) + ' €';
                if (goalType === 'dch') {
                    document.getElementById('js-additional-1').innerText = formatNumberToString(finalValue / (inputYear * 12)) + ' €';
                    var newFinalValue = finalValue / (inputYear * 12);
                    document.getElementById('js-calc-price-dch').value = newFinalValue.toFixed(0);
                }
                if (goalType === 'det') {
                    document.getElementById('js-calc-price-det').value = finalValue.toFixed(0);
                }
            }
            if (pole === 2) {
                myLine.data.datasets[2].data = dataArray;
                document.getElementById('js-type-2').parentElement.className = 'riz';
                document.getElementById('js-type-2').innerText = 'Dynamický';
                document.getElementById('js-type-val-2').innerText = '10% p.a.';
                document.getElementById('js-hodnota-2').innerText = formatNumberToString(finalValue) + ' €';
                document.getElementById('js-zisk-2').innerText = formatNumberToString(finalInvest) + ' €';
                document.getElementById('js-invest-2').innerText = formatNumberToString(finalProfit) + ' €';
                if (goalType === 'dch') {
                    document.getElementById('js-additional-2').innerText = formatNumberToString(finalValue / (inputYear * 12)) + ' €';
                }
            }
        } else if (type === 'riz') {
            myLine.data.datasets[0].data = [];
            if (pole === 2) {
                myLine.data.datasets[2].data = dataArray;
                document.getElementById('js-type-1').parentElement.className = 'riz';
                document.getElementById('js-type-1').innerText = 'Dynamický';
                document.getElementById('js-type-val-1').innerText = '10% p.a.';
                document.getElementById('js-hodnota-1').innerText = formatNumberToString(finalValue) + ' €';
                document.getElementById('js-zisk-1').innerText = formatNumberToString(finalInvest) + ' €';
                document.getElementById('js-invest-1').innerText = formatNumberToString(finalProfit) + ' €';
                if (goalType === 'dch') {
                    document.getElementById('js-additional-1').innerText = formatNumberToString(finalValue / (inputYear * 12)) + ' €';
                    var newFinalValue = finalValue / (inputYear * 12);
                    document.getElementById('js-calc-price-dch').value = newFinalValue.toFixed(0);
                }
                if (goalType === 'det') {
                    document.getElementById('js-calc-price-det').value = finalValue.toFixed(0);
                }
            }
            if (pole === 1) {
                myLine.data.datasets[1].data = dataArray;
                document.getElementById('js-type-2').parentElement.className = 'dyn';
                document.getElementById('js-type-2').innerText = 'Vyvážený';
                document.getElementById('js-type-val-2').innerText = '8% p.a.';
                document.getElementById('js-hodnota-2').innerText = formatNumberToString(finalValue) + ' €';
                document.getElementById('js-zisk-2').innerText = formatNumberToString(finalInvest) + ' €';
                document.getElementById('js-invest-2').innerText = formatNumberToString(finalProfit) + ' €';
                if (goalType === 'dch') {
                    document.getElementById('js-additional-2').innerText = formatNumberToString(finalValue / (inputYear * 12)) + ' €';
                }
            }
        }
        myLine.data.datasets[3].data = [];
        myLine.data.datasets[0].label = 'Konzervatívny';
        myLine.data.datasets[1].label = 'Vyvážený';
        myLine.data.datasets[2].label = 'Dynamický';
        myLine.data.datasets[3].label = '';
        document.getElementById('js-calc-price-ine').innerText = formatNumberToString(myLine.data.datasets[2].data[rok + 1] - myLine.data.datasets[1].data[rok]) + ' €';

        if (goalType === 'dch') {
            showAdditionalCalcText();
            document.getElementById('js-additionalText').innerText = 'Dôchodkový príjem';
            document.querySelector('.js-dch-calc').value = inputYear;
        } else {
            removeAdditionalCalcText();
        }
    } else if (goalType === 'ine') {
        myLine.data.datasets[1].label = rok + ' rok';
        myLine.data.datasets[2].label = (rok + 5) + ' rok';
        myLine.data.datasets[0].data = [];
        myLine.data.datasets[3].data = [];
        myLine.data.datasets[1].data = dataArrayIne2;
        myLine.data.datasets[2].data = dataArrayIne3;
        document.getElementById('js-type-1').parentElement.className = 'dyn';
        document.getElementById('js-type-1').innerText = rok + ' rok';
        document.getElementById('js-type-val-1').innerText = '10% p.a.';
        document.getElementById('js-hodnota-1').innerText = formatNumberToString(n2) + ' €';
        document.getElementById('js-zisk-1').innerText = formatNumberToString(n2 - vklad) + ' €';
        document.getElementById('js-invest-1').innerText = formatNumberToString(vklad) + ' €';
        document.getElementById('js-type-2').parentElement.className = 'riz';
        document.getElementById('js-type-2').innerText = (rok + 5) + ' rok';
        document.getElementById('js-type-val-2').innerText = '10% p.a.';
        document.getElementById('js-hodnota-2').innerText = formatNumberToString(n3) + ' €';
        document.getElementById('js-zisk-2').innerText = formatNumberToString(n3 - vklad) + ' €';
        document.getElementById('js-invest-2').innerText = formatNumberToString(vklad) + ' €';
        document.getElementById('js-calc-price-ine').innerText = formatNumberToString(n2) + ' €';
    }

    if (goalType === 'hyp') {
        myLine.data.datasets[3].label = 'Zostatok hypotéky';
        myLine.data.datasets[3].data = dataArrayHyp;
        var mainArr;
        setHypoYear()
    }
}

function removeAdditionalCalcText() {
    document.getElementById('js-additionalCalc').style.display = 'none';
}

function showAdditionalCalcText() {
    document.getElementById('js-additionalCalc').style.display = 'flex';
}

function setYears() {
    var currentValue = getValue();
    var currentYearsEle = getYears();
    var currentYears = calcYears(currentYearsEle.noUiSlider.get());
    var currentMortgage = getMortgage();
    var currentMortgageRate = getMortgageRate();
    customUpdateChart(
        document.querySelector('.calculator-content-goal .selected').getAttribute('data-goal'),
        document.querySelector('.calculator-content-type .selected').getAttribute('data-type'),
        currentYears,
        currentValue,
        currentMortgage,
        currentMortgageRate
    );
}

function calcYears(el) {
    var year;

    if (Array.isArray(el)) {
        year = Math.round(el[1] - el[0]);
    } else {
        year = Math.round(el)
    }

    return year;
}

function formatNumberToString(e, a) {
    a = isNaN(a = Math.abs(a)) ? 2 : a;
    var t = " ",
        n = ",",
        r = 0 > e ? "-" : "",
        o = parseInt(e = Math.abs(+e || 0).toFixed(a), 10) + "",
        d = (d = o.length) > 3 ? d % 3 : 0;
    return r + (d ? o.substr(0, d) + t : "") + o.substr(d).replace(/(\d{3})(?=\d)/g, "$1" + t) + (a ? n + Math.abs(e - o).toFixed(a).slice(2) : "")
}

function formatNumberToNumber(e) {
    return Number(e.toFixed(2));
}

function getValue() {
    var valueWrappers = document.querySelectorAll('.calculator-content-value-wrapper');
    var valueParent;
    Array.prototype.slice.call(valueWrappers).forEach(element => {
        if (element.style.display === 'block') {
            valueParent = element
        }
    });
    var currentValue = valueParent.querySelector('.calculator-content-value .selected').getAttribute('data-value');
    document.querySelector('.js-hypo-value').value = currentValue;
    return currentValue;
}

function getYears() {
    var valueWrappers = document.querySelectorAll('.calculator-content-period-wrapper');
    var valueParent;
    Array.prototype.slice.call(valueWrappers).forEach(element => {
        if (element.style.display === 'block') {
            valueParent = element
        }
    });
    var currentValue = valueParent.querySelector('.js-period-slider');
    return currentValue;
}

function getMortgage() {
    var currentValue = document.getElementById('hyp-slider-uver').noUiSlider.get();
    return currentValue;
}

function getMortgageRate() {
    var currentValue = document.getElementById('hyp-slider-urok').noUiSlider.get();
    return currentValue;
}

//Reverse functions

function calcPrice(PRICE, PERC) {
    return PRICE + ((PERC / 100) * PRICE);
}

function rok(HOW_MANY) {
    return HOW_MANY * 12;
}

function investicna_kalkulacka(mesiace, nasporene = 0, investicia = _investicia) {
    return mesiace === 0 ? nasporene : investicna_kalkulacka(mesiace - 1, investicia + calcPrice(nasporene, type / 12), investicia);
}

var type,
    finalna_suma,
    chcem_dosiahnut;

function interpolacia(_odhad = (finalna_suma / rok(chcem_dosiahnut)), investicny_predpoklad = investicna_kalkulacka(rok(chcem_dosiahnut),0, (finalna_suma / rok(chcem_dosiahnut))), chybovost = 0.5) {
    if(investicny_predpoklad - finalna_suma > 0) {
        let novy_odhad = _odhad - ((chybovost / 100) * _odhad);
        return interpolacia(novy_odhad, investicna_kalkulacka(rok(chcem_dosiahnut),0, novy_odhad), chybovost)
    }
    return _odhad;
}


var inputs = document.querySelectorAll('input.js-calc-btn');
inputs.forEach(element => {
    element.addEventListener('onchange', function () {
        element.setAttribute('data-value', element.value);
        toggleSelected(element);
    });
    element.addEventListener('input', function () {
        element.setAttribute('data-value', element.value);
        toggleSelected(element);
    });
});

if(document.querySelector('.js-hypo-value')) {
    document.querySelector('.js-hypo-value').addEventListener('input', function (element) {
        var valueWrappers = document.querySelectorAll('.calculator-content-value-wrapper');
        var valueParent;
        Array.prototype.slice.call(valueWrappers).forEach(element => {
            if (element.style.display === 'block') {
                valueParent = element
            }
        });
        var currentInput = valueParent.querySelector('.calculator-content-value input');
        currentInput.value = this.value;
        currentInput.setAttribute('data-value', this.value);
        toggleSelected(currentInput);
    });
}

Array.prototype.slice.call(document.querySelectorAll('.js-focus-reset')).forEach(element => {
    var tempVal;
    element.addEventListener('focus', function () {
        tempVal = element.value;
        this.value = '';
    });
    element.addEventListener('blur', function () {
        if (tempVal !== this.value && this.value === '') {
            this.value = tempVal;
        }
    });
});


Chart.plugins.register({
    afterDatasetsDraw: function (chart) {
        if (chart.tooltip._active && chart.tooltip._active.length) {
            var activePoint = chart.tooltip._active[0],
                ctx = chart.ctx,
                y_axis = chart.scales['y-axis-0'],
                x = activePoint.tooltipPosition().x,
                topY = y_axis.top,
                bottomY = y_axis.bottom;
            // draw line
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 2;
            ctx.strokeStyle = '#00b8ce';
            ctx.stroke();
            ctx.restore();
        }
    }
});

var config = {
    type: 'line',
    data: {
        datasets: [{
            label: 'Konzervatívny',
            backgroundColor: '#00b8ce',
            borderColor: '#00b8ce',
            fill: false,
            lineTension: 0,
            pointRadius: 0,
            pointHoverRadius: 8,
            hitRadius: 10
        }, {
            label: 'Vyvážený',
            backgroundColor: '#c165f0',
            borderColor: '#c165f0',
            fill: false,
            lineTension: 0,
            pointRadius: 0,
            pointHoverRadius: 8,
            hitRadius: 10
        }, {
            label: 'Dynamický',
            backgroundColor: '#4b63f2',
            borderColor: '#4b63f2',
            fill: false,
            lineTension: 0,
            pointRadius: 0,
            pointHoverRadius: 8,
            hitRadius: 10
        }, {
            label: '',
            backgroundColor: '#e91e63',
            borderColor: '#e91e63',
            fill: false,
            lineTension: 0,
            pointRadius: 0,
            pointHoverRadius: 8,
            hitRadius: 10
        }]
    },
    options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                left: 10,
                right: 10,
                top: 10,
                bottom: 10
            }
        },
        animation: {
            duration: 0,
        },
        legend: {
            labels: {
                fontSize: 15,
                filter: function (item, chart) {
                    if (chart.datasets[item.datasetIndex].data.length) {
                        return item;
                    }
                }
            }
        },
        tooltips: {
            xPadding: 20,
            yPadding: 20,
            titleFontSize: 16,
            bodyFontSize: 15,
            position: 'nearest',
            backgroundColor: '#fff',
            titleFontColor: '#000',
            bodyFontColor: '#000',
            footerFontColor: '#000',
            mode: 'index',
            axis: 'y',
            callbacks: {
                title: function (tooltipItem, data) {
                    var title = tooltipItem[0].xLabel || '';

                    if (title) {
                        title += ' rok';
                    } else {
                        title += 'Počiatočný stav';
                    }

                    return title;
                },

                label: function (tooltipItem, data) {
                    var label = " " + tooltipItem.yLabel.toLocaleString() + ' €' + " - " + data.datasets[tooltipItem.datasetIndex].label || '';
                    return label;
                }
            }
        },
        hover: {
            mode: 'index'
        },
        scales: {
            xAxes: [{
                ticks: {
                    display: false,
                },
                gridLines: {
                    drawBorder: false
                }
            }],
            yAxes: [{
                display: false,
            }]
        },
    }
};

var chart = document.getElementById('myChart');
if(chart) {
    var ctx = chart.getContext('2d');
    window.myLine = new Chart(ctx, config);
    showCalcContent('hyp');
    initChart('hyp');
}
function initChart(goal) {
    if (goal) {
        toggleSelected(document.querySelector(`.calculator-content-goal [data-goal="${goal}"]`));
    }
    var currentValue = getValue();
    var currentYearsEle = getYears();
    var currentYears = calcYears(currentYearsEle.noUiSlider.get());
    var currentMortgage = getMortgage();
    var currentMortgageRate = getMortgageRate();
    customUpdateChart(
        document.querySelector('.calculator-content-goal .selected').getAttribute('data-goal'),
        document.querySelector('.calculator-content-type .selected').getAttribute('data-type'),
        currentYears,
        currentValue,
        currentMortgage,
        currentMortgageRate
    );
}
